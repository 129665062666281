import * as React from "react";
import { graphql } from "gatsby";
import PageComponent from "src/components/pages/blog";

const BlogPage = ({ data, location }) => {
  const posts = data.allFile.nodes.map((node) => ({
    date: node.childMdx.frontmatter.date,
    title: node.childMdx.frontmatter.title,
    id: node.childMdx.id,
    body: node.childMdx.body,
    excerpt: node.childMdx.excerpt,
    href: `/blog/${node.childMdx.slug}`,
  }));

  const copy = {
    morePosts: "More blog posts",
  };

  return (
    <PageComponent
      lang="en"
      location={location}
      posts={posts}
      copy={copy}
      pageTitle="Blog"
    />
  );
};

export const query = graphql`
  query ListBlogPosts {
    allFile(
      filter: {
        childMdx: { id: { ne: null } }
        sourceInstanceName: { eq: "blog" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          body
          id
          excerpt
          slug
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
      totalCount
    }
  }
`;

export default BlogPage;
